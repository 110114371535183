
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";
.width{
    width: fit-content;
}
// .why-us {
//     .car-secure {
//         svg {
//             width: 100%;
//             height: auto;
//             @include md {
//                 margin: 0 auto;
//             }
//             @include lg {
//                 margin-right: 0;
//             }
//         }
//         @include md {
//             margin-top: 30px;
//         }
//     }
// }

.list-item {
  
    @include lg {
        width: 28%;
    }
}

// .heading {
//     color: $perfect-auto-warranty-black-lead;
//     p {
//         color: #919191;
//     }
// }
